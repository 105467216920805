<template>
  <div class="col-xl-9 col-md-8" v-if="workflow">
    <fp-panel-card style="background: white; margin: 35px 0px">
      <template #content>
        <b>{{ workflow.Name }}</b>
        <br />
        {{ workflow.Description }}
        <div v-if="userLists">
          <fp-select
            v-for="(hint, name, index) in workflow.ParticipantRoles"
            :key="index"
            :label="name"
            :placeholder="hint"
            :items="userLists[name]"
            valueKey="UserId"
            textKey="Name"
            :searchable="true"
            @search="filterList(hint, name, $event)"
            v-model="newInstance.Participants[name]"
          >
            <template #element="e">
              {{
                hint && hint.includes("Patient")
                  ? (e.Name ? e.Name : "") +
                    " (" +
                    e.Username +
                    (e.Ssn ? ", TAJ: " + e.Ssn : "") +
                    ")"
                  : e.Name + "( " + e.Username + ")"
              }}
            </template>
          </fp-select>
        </div>
        <button @click="instantiateWorkflow" class="eh-action-button">
          {{ $t("healthProfessional.workflow.startWorkflow") }}
        </button>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
import { UserLogic } from "../../Logic/Backend/user";
import { WorkflowLogic } from "../../Logic/Backend/workflow";
export default {
  name: "CreateWorkflowInstance",
  props: {
    selectedWorkflow: Object,
  },
  data() {
    return {
      workflow: this.selectedWorkflow,
      newInstance: { ProjectId: null, WorkflowId: null, Participants: {} },
      userLists: {},
    };
  },
  watch: {
    selectedWorkflow(input) {
      this.workflow = input;
      if (input) {
        this.getDefaultParticipantList();
      }
    },
  },
  methods: {
    async getDefaultParticipantList() {
      await Promise.all(
        Object.entries(this.workflow.ParticipantRoles).map(
          async ([name, hint]) => {
            await this.filterList(hint, name);
          }
        )
      );
    },
    async filterList(hint, name, input) {
      if (input && input.length > 2) {
        const userList = await this.getUserListByHint(
          {
            multiValue: input,
            "-orderby": "Username",
          },
          name,
          hint
        );
        this.$set(this.userLists, name, userList);
      } else if (input) {
        this.userLists[name] = null;
        this.$set(this.userLists, name, null);
      } else {
        const userList = await this.getUserListByHint(
          {
            "-orderby": "Username",
            "-limit": 20,
          },
          name,
          hint
        );
        this.$set(this.userLists, name, userList);
      }
    },
    async getUserListByHint(params, name, hint) {
      //TODO: getUser helyett subordinate?
      //a doki mennyire oszthat ki más dokinak, más felhasználónak (kapcsolat nélkül)
      const getUserListResponse = await UserLogic.getUserData(params);
      if (!getUserListResponse.Code) {
        var temp = [];
        if (hint) {
          if (hint.includes("Self")) {
            temp = getUserListResponse;
            if (
              !getUserListResponse.some(
                (user) => user.UserId == this.$loggedUser.UserId
              )
            ) {
              temp.splice(0, 0, this.$loggedUser);
            }
            this.newInstance.Participants[name] = this.$loggedUser.UserId;
          } else if (hint.includes("Patient")) {
            temp = getUserListResponse.filter((user) =>
              user.Roles.includes("Client")
            );
          } else if (hint.includes("Doctor")) {
            temp = getUserListResponse.filter((user) =>
              user.Roles.includes("HealthProfessional")
            );
          }
        }
        return temp;
      } else {
        this.$bvToast.toast(getUserListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("healthProfessional.tasks.errorUserName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      return null;
    },
    async instantiateWorkflow() {
      this.newInstance.WorkflowId = this.workflow.WorkflowId;
      this.newInstance.ProjectId = this.$loggedUser.SelectedProject.ProjectId;
      await this.creatWorkflowIntance();
    },
    async creatWorkflowIntance() {
      const creatWorkflowIntanceResponse = await WorkflowLogic.setInstantiate(
        this.newInstance
      );
      if (!creatWorkflowIntanceResponse.Code) {
        await this.startWorkflow(creatWorkflowIntanceResponse);
      } else {
        this.$bvToast.toast(creatWorkflowIntanceResponse.Message, {
          title: this.$t("requestResponse.workflow.errorCreateInsatnce"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async startWorkflow(workflowInstance) {
      const startWorkflowResponse = await WorkflowLogic.startWorkflow(
        workflowInstance.InstanceId
      );
      if (!startWorkflowResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.workflow.successStart"), {
          title: this.$t("requestResponse.workflow.successStartTitle"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(startWorkflowResponse.Message, {
          title: this.$t("requestResponse.workflow.errorStart"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
};
</script>
